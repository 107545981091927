<template>
    <div class="row f-12 f-500 py-4 justify-content-between bg-f6 shadow3-5-29">
        <div class="col-auto my-auto">
            <div class="d-middle">
                <div class="wh-74 border-general2 br-4 bg-white">
                    <img :src="img" alt="" class="w-100 h-100 br-4">
                </div>
               <div class="pl-2">
                    <p class="text-general f-600 mb-3">Equipo:</p>
                    <p> {{ equipo }} </p>
               </div>
            </div>
        </div>
        <div class="col-auto my-auto">
            <div class="d-flex">
                <p class="text-general f-600 mb-3">Etapa:</p>
                <p class="pl-2"> {{ etapa }} </p>
            </div>
            <div class="d-flex">
                <p class="text-general f-600 mb-3">Configuración:</p>
                <p class="pl-2"> {{ configuracion }} </p>
            </div>
        </div>
        <div class="col-auto my-auto">
            <div class="d-flex">
                <p class="text-general f-600 mb-3">Plan de mantenimiento:</p>
                <p class="pl-2"> {{ planMantenimiento  }} </p>
            </div>
            <div class="d-flex">
                <p class="text-general f-600 mb-3">Material:</p>
                <p class="pl-2"> {{ material }} </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        id:{
            type: Number,
            required: true,
        },
        img:{
            type: String,
        },
        equipo:{
            type: String,
        },
        etapa:{
            type: Number
        },
        planMantenimiento:{
            type: Number
        },
        configuracion:{
            type: Number
        },
        material:{
            type: Number
        },
    }

}
</script>

<style lang="scss" scoped>
.shadow3-5-29{
    box-shadow: 0px 3px 5px #00000029;
}
</style>