<template>
    <div class="d-middle border-bottom py-3">
        <img :src="img" alt="" width="50" height="50">
        <p class="f-12 px-2"> {{ nombre }} </p>
        <el-checkbox v-model="checked" class="ml-auto" />
    </div>
</template>

<script>
export default {
    props:{
        id:{
            type: Number
        },
        img:{
            type: String
        },
        nombre:{
            type: String
        },
    },
    data() {
        return {
            checked: false,
        }
    },

}
</script>

<style>

</style>