<template>
    <section class="calendarios-main container-fluid">
        <navbar-admin icon="icon-calendar-multiselect" />
        <!-- info top old -->
        <!-- <div class="row f-12 f-500 py-3 border-bottom-g justify-content-between d-none">
            <div class="col-auto">
                <div class="d-flex">
                    <p class="text-general">Oportunidad:</p>
                    <p class="pl-2">Alquería</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-flex">
                    <p class="text-general">Proyecto:</p>
                    <p class="pl-2">Reactor de leche fluidizado</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-flex">
                    <p class="text-general">Etapa:</p>
                    <p class="pl-2">Alquería</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-flex">
                    <p class="text-general">Configuración:</p>
                    <p class="pl-2">Efeco 1</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-flex">
                    <p class="text-general">Plan de mantenimiento:</p>
                    <p class="pl-2">Limpieza seca</p>
                </div>
            </div>
        </div> -->
        <!-- info top new -->
        <info-top :id="1" 
        :img="cabecera.imagen" 
        :equipo="cabecera.nombre" 
        :etapa="cabecera.etapas"
        :planMantenimiento="cabecera.plan_mantenimiento"
        :configuracion="cabecera.configuraciones"
        :material="cabecera.materiales" />
        <!-- filtro -->
        <div class="row my-3 justify-content-end">
        </div>
        <div class="row my-4 justify-content-end">
            <div class="col-auto icon-option my-auto pl-0">
                <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                    <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltrosEquipo">
                        <i class="icon-filter text-white" />
                    </button>
                </el-tooltip>
            </div>
            <div class="col-auto d-middle my-auto router-buttons justify-content-end f-15">
                <router-link :to="{ name:'calendario.year' }" class="br-l-4 ">
                    Año
                </router-link>
                <router-link :to="{ name:'calendario.mes' }" class="">
                    Mes
                </router-link>
                <router-link :to="{ name:'calendario.semanas' }" class="">
                    Semana
                </router-link>
                <router-link :to="{ name:'calendario.lista' }" class="br-r-4 ">
                    Lista
                </router-link>
            </div>
        </div>
        <router-view ref="tipoVista" />
         <!-- partials -->
        <!-- <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-2">
                    <p class="input-label-top text-general f-500">Oportunidad</p>
                    <el-select v-model="valueSelect" placeholder="Seleccionar oportunidad" size="small" class="w-100">
                        <el-option v-for="item in optionsOp" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-11 my-2">
                    <p class="input-label-top text-general f-500">Proyecto</p>
                    <el-select v-model="valueProyectos" placeholder="Seleccionar proyecto" size="small" class="w-100">
                        <el-option v-for="item in optionsProyectos" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-11 my-2 text-right">
                    <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterAccept1"> Aceptar </button>
                </div>
                <div class="col-11">
                    <hr />
                </div>
                <div class="col-11 my-2">
                    <p class="input-label-top text-general f-500">Etapa</p>
                    <el-select v-model="valueEtapas" placeholder="Seleccionar etapa" size="small" class="w-100" :disabled="disabledSelects">
                        <el-option v-for="item in optionsEtapas" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-11 my-2">
                    <p class="input-label-top text-general f-500">Material</p>
                    <el-select v-model="valueMateriales" placeholder="Seleccionar material" size="small" class="w-100" :disabled="disabledSelects">
                        <el-option v-for="item in optionsMaterials" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-11 my-2">
                    <p class="input-label-top text-general f-500">Configuración</p>
                    <el-select v-model="valueConfiguraciones" placeholder="Seleccionar configuración" size="small" class="w-100" :disabled="disabledSelects">
                        <el-option v-for="item in optionsConfiguraciones" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-11 my-2">
                    <p class="input-label-top text-general f-500">Plan de mantenimiento</p>
                    <el-select v-model="valuePlanes" placeholder="Seleccionar plan" size="small" class="w-100" :disabled="disabledSelects">
                        <el-option v-for="item in optionsPlans" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-11 my-2">
                    <p class="input-label-top text-general f-500">Actividad</p>
                    <el-select v-model="valueActividades" placeholder="Seleccionar actividad" size="small" class="w-100" :disabled="disabledSelects">
                        <el-option v-for="item in optionsActividades" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="abrirModalFiltros"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="abrirModalFiltros"> Filtrar </button>
            </div>
        </modal-lateral> -->
        <!-- filter #2 -->
        <modal-lateral ref="abrirModalFiltrosEquipo" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 text-center">
                    <p class="text-5d f-600 f-13 pb-2 border-bottom">Elija el equipo</p>
                </div>
                <!-- <div class="col-12 my-3">
                    <check-equipo v-for="(data,idx) in listadoEquipos" :key="idx" :img="data.img" :nombre="data.nombre" />
                </div> -->
                <div class="col-12 my-3 custom-scroll" style="height: calc(100vh - 181px); overflow-y: auto; overflow-x: hidden;">
                    <el-radio-group v-model="checkList">
                        <el-radio v-for="(data, index) in equipos" :key="index" :label="index" class="border-bottom w-100" style="display: flex; align-items: center;">
                            <div class="d-middle py-3 w-100">
                                <img :src="data.imagen" alt="" width="50" height="50">
                                <p class="f-12 px-2"> {{ data.nombre }} </p>
                            </div>
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="abrirModalFiltros"> Aceptar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import moment from 'moment'
import infoTop from './components/infoTop.vue'
import checkEquipo from './components/checkEquipo.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    components:{
        infoTop,
        checkEquipo,
    },
    data() {
        return {
            checkList: 0,
            infoTop:{
                    id: 1,
                    img: '/img/pruebas/equipo1.png',
                    equipo: 'Reactor de leche fluidizado',
                    etapa: 'Efecto 1',
                    planMantenimiento: 'Plan de mantenimiento',
                    configuracion: 'Configuración',
                    material: 'Material'
            },
            listadoEquipos:[
                {
                    id: 1,
                    img: '/img/pruebas/equipo1.png',
                    nombre: 'Reactor de lecho fluidizado'
                },
                {
                    id: 2,
                    img: '/img/pruebas/equipo2.png',
                    nombre: 'Spray Dryers'
                },
            ],
            optionsOp: [
                {
                    value: 1,
                    label: 'Oportunidad 1'
                }, 
                {
                    value: 2,
                    label: 'Oportunidad 2'
                }, 
                {
                    value: 3,
                    label: 'Oportunidad 3'
                }, 
                {
                    value: 4,
                    label: 'Oportunidad 4'
                }, 
                {
                    value: 5,
                    label: 'Oportunidad 5'
                }
            ],
            optionsProyectos: [
                {
                    value: 1,
                    label: 'Proyecto 1'
                }, 
                {
                    value: 2,
                    label: 'Proyecto 2'
                }, 
                {
                    value: 3,
                    label: 'Proyecto 3'
                }, 
                {
                    value: 4,
                    label: 'Proyecto 4'
                }, 
                {
                    value: 5,
                    label: 'Proyecto 5'
                }
            ],
            optionsEtapas: [
                {
                    value: 1,
                    label: 'Etapa 1'
                }, 
                {
                    value: 2,
                    label: 'Etapa 2'
                }, 
                {
                    value: 3,
                    label: 'Etapa 3'
                }, 
                {
                    value: 4,
                    label: 'Etapa 4'
                }, 
                {
                    value: 5,
                    label: 'Etapa 5'
                }
            ],
            optionsConfiguraciones: [
                {
                    value: 1,
                    label: 'Configuración 1'
                }, 
                {
                    value: 2,
                    label: 'Configuración 2'
                }, 
                {
                    value: 3,
                    label: 'Configuración 3'
                }, 
                {
                    value: 4,
                    label: 'Configuración 4'
                }, 
                {
                    value: 5,
                    label: 'Configuración 5'
                }
            ],
            optionsMaterials: [
                {
                    value: 1,
                    label: 'Material 1'
                }, 
                {
                    value: 2,
                    label: 'Material 2'
                }, 
                {
                    value: 3,
                    label: 'Material 3'
                }, 
                {
                    value: 4,
                    label: 'Material 4'
                }, 
                {
                    value: 5,
                    label: 'Material 5'
                }
            ],
            optionsPlans: [
                {
                    value: 1,
                    label: 'Plan 1'
                }, 
                {
                    value: 2,
                    label: 'Plan 2'
                }, 
                {
                    value: 3,
                    label: 'Plan 3'
                }, 
                {
                    value: 4,
                    label: 'Plan 4'
                }, 
                {
                    value: 5,
                    label: 'Plan 5'
                }
            ],
            optionsActividades: [
                {
                    value: 1,
                    label: 'Actividad 1'
                }, 
                {
                    value: 2,
                    label: 'Actividad 2'
                }, 
                {
                    value: 3,
                    label: 'Actividad 3'
                }, 
                {
                    value: 4,
                    label: 'Actividad 4'
                }, 
                {
                    value: 5,
                    label: 'Actividad 5'
                }
            ],
            valueSelect: '',
            valueProyectos: '',
            valueEtapas: '',
            valueMateriales: '',
            valueConfiguraciones: '',
            valuePlanes: '',
            valueActividades: '',
            disabledSelects: true,
            filterProduct:{},
            id_equipo:{},
            id_equipos_modelos:{}
        }
    },
    computed: {
        ...mapGetters({
            equipos: 'calendario/calendario/equipos',
            cabecera: 'calendario/calendario/cabecera',
            fechaCalendario: 'calendario/calendario/fechaCalendario',
        }),
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'calendario.main'
        ]);
        await this.Action_get_equipos();
        await this.listCalendarioYear();
        await this.getCabecera();
        // this.$refs.tipoVista.setData()
        
    },
    methods: {
        ...mapActions({
            Action_get_equipos: 'calendario/calendario/Action_get_equipos',
            Action_get_calendario_annio: 'calendario/calendario/Action_get_calendario_annio',
            Action_get_cabecera_calendario: 'calendario/calendario/Action_get_cabecera_calendario',
            Action_get_calendario_mes: 'calendario/calendario/Action_get_calendario_mes',
            Action_get_calendario_semana: 'calendario/calendario/Action_get_calendario_semana',

        }),
        ...mapMutations({
            setFechaCalendario: 'calendario/calendario/setFechaCalendario',
        }),
        async listCalendarioYear(){
            if(!this.equipos.length) return 
            const fecha = this.fechaCalendario ? this.fechaCalendario : moment().format('YYYY-MM-DD')
            this.setFechaCalendario(fecha)
            // this.Action_update_date(fecha)
            if (this.equipos[this.checkList].tabla == 'productos') {
                this.id_equipo = {id_producto:this.equipos[this.checkList].id, id_ops:this.equipos[this.checkList].id_ops}
            }else if (this.equipos[this.checkList].tabla == 'equipos_modelos'){
                this.id_equipos_modelos = {id_equipos_modelos:this.equipos[this.checkList].id, id_ops:this.equipos[this.checkList].id_ops}
            }

            const payload = {
                id_equipo:!_.isEmpty(this.id_equipo) ? this.id_equipo : null,
                id_equipos_modelos:!_.isEmpty(this.id_equipos_modelos) ? this.id_equipos_modelos : null,
            }
            await this.Action_get_calendario_annio(payload)
        },
        async abrirModalFiltros(){
            await this.listCalendarioYear()
            await this.getCabecera()
            this.limpiar()
            this.$refs.abrirModalFiltrosEquipo.toggle()
            this.$refs.tipoVista.setData()
        },
        abrirModalFiltrosEquipo(){
            this.$refs.abrirModalFiltrosEquipo.toggle()
        },
        filterAccept1(){
            this.disabledSelects = false
        },
        async getCabecera(){
            const payload = {
                id_equipo:!_.isEmpty(this.id_equipo) ? this.id_equipo : null,
                id_equipos_modelos:!_.isEmpty(this.id_equipos_modelos) ? this.id_equipos_modelos : null,
                semana: moment(this.fechaCalendario).week(),
                anio: moment(this.fechaCalendario).format('YYYY'),
                mes: moment(this.fechaCalendario).format('MM')
            }

            await this.Action_get_cabecera_calendario(payload)
            await this.Action_get_calendario_mes(payload)
            await this.Action_get_calendario_semana(payload)
            this.limpiar()
         },
         limpiar(){
            this.id_equipo = {}
            this.id_equipos_modelos = {}
         }
    },

}
</script>

<style lang="scss" scoped>
.calendarios-main{
    .router-buttons{
        a{
            background: #DDDDDD;
            color: #5D5D5D;
            font-size: 12px;
            padding: 8px;
            &.router-link-exact-active{
                background: var(--color-general);
                color: #fff;
            }
            &:hover{
                text-decoration: none;
                background: var(--color-general);
                color: #fff;
            }
        }
    }
}
</style>